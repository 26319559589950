import React, { ReactElement } from "react";
import Logo from "@components/lawyerconsultation/logo";
import styles from "./styles.module.scss";
import { useRouter } from "next/router";
import { useTranslation } from "@hooks/useTranslation";
import colors from "@styles/variables/lawyerconsultationVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import ProxiedNumber from "@components/shared/proxiedNumber";
import { getHomepageHref } from "@lib/sharedUtils";
import { useCallCampaignContext } from "@hooks/useCallCampaignContext";

export default function Header({
    headerMessage,
    hideSticky,
    waitUntilValidateRecaptcha,
}: {
    headerMessage?: string | null | undefined;
    hideSticky?: boolean;
    waitUntilValidateRecaptcha?: boolean;
}): ReactElement {
    const { query } = useRouter();

    const { translate } = useTranslation();
    const { locale, defaultLocale } = useDomainContext();

    const { showPhoneNumber } = useCallCampaignContext();

    let phoneNumberBlock = (
        <ProxiedNumber
            slogan={headerMessage ?? translate("SLOGAN")}
            phoneNumberColor="#fff"
            hideSticky={hideSticky}
            stickyStyle={{
                maxWidth: "1536px",
            }}
        />
    );

    if (waitUntilValidateRecaptcha) {
        phoneNumberBlock = showPhoneNumber ? (
            <ProxiedNumber
                slogan={headerMessage ?? translate("SLOGAN")}
                phoneNumberColor="#fff"
                hideSticky={hideSticky}
                stickyStyle={{
                    maxWidth: "1536px",
                }}
            />
        ) : (
            <></>
        );
    }

    return (
        <header className={styles.hdrWrapper}>
            <div
                className={`${styles.hdrContainer} max-w-screen-2xl mx-auto py-3`}
            >
                <div
                    className={`${styles.hdr} flex justify-between items-center w-full`}
                >
                    <div className={`${styles.logo}`}>
                        <a href={getHomepageHref(query, locale, defaultLocale)}>
                            <Logo color={colors.lcSecondary} />
                        </a>
                    </div>

                    <div className={`flex relative ${styles["phoneNumber"]}`}>
                        <div className="flex items-center">
                            <span
                                className={`${styles.slogan} ml-3 text-xs font-normal`}
                            >
                                {phoneNumberBlock}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
